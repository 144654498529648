import React from "react"
import { graphql } from "gatsby"
import format from "date-fns/format"
import Layout from "../components/layout"

const BlogPage = ({ data }) => {
  const { title, body, publishDate } = data.graphCmsBlogPost

  return (
    <Layout>
      <div className="prose lg:prose-xl mx-auto max-w-5xl py-6">
      
            {publishDate && <label>{format(new Date(publishDate), "EEEE do MMMM yyyy")}</label>}
    
        <h1>{title}</h1>
        
        <div
          dangerouslySetInnerHTML={{
            __html: body.html,
          }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($title: String) {
    graphCmsBlogPost(title: {eq: $title}) {
      title
      body {
        html
      }
      publishDate
    }
  }
`

export default BlogPage
